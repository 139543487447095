:root {
  --color-main: rgb(9 9 11);
  --color-sub: rgb(176 171 178);
  --color-switch: rgb(0 123 255);
  --color-select: rgb(228, 228, 231);
  --text-decoration-thickness: 0.04em;
  --text-decoration-offset: 0.25em;
}

::selection,
::-moz-selection {
  background: var(--color-select);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: aktiv-grotesk, a-otf-gothic-bbb-pr6n, BlinkMacSystemFont, -apple-system, -system-ui, sans-serif;
  margin: 3.2rem 1.6rem;
}

@media (min-width: 500px) {
  body {
    margin: 6rem 3rem;
  }
}

article {
  font-feature-settings: 'palt';
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 0.12em;
}

@media (min-width: 500px) {
  article {
    font-size: 1.4rem;
  }
}

h1 {
  font-weight: 400;
  margin: 0;
  font-size: 1.5rem;
}

@media (min-width: 500px) {
  h1 {
    font-size: 1.8rem;
  }
}

/*
 * 共通のスタイリング
 */
.typesetting-wrapper {
  /*
   * Safari のフォントレンダリング対策。
   * 英数（.typesetting-latin）で -webkit-text-stroke を使う場合は必須。
   * text-stroke-weight > 0, text-stroke-color: transparent
   */
  -webkit-text-stroke: 0.01em transparent;
}

/*
 * 英数のみのスタイリング
 */
.typesetting-latin {
  /* フォントの拡大・縮小 */
  font-size: 104%;

  /* ベースラインの調整 */
  vertical-align: 0.02em;

  /*
   * 行間の調整。親要素 .typesetting の行間と視覚的に合わせます。
   * [.typesettingの line-height] ÷ [フォントの拡大率] - [ベースラインの調整値の絶対値] × 2 
   */
  line-height: calc(2 / 1.04 - 0.02 * 2);

  /* 文字間の調整 */
  letter-spacing: 0.05em;

  /* 文字の細らせ・太らせ */
  -webkit-text-stroke: 0.014em var(--color-main);

  /*
   * フォールバックフォントのウェイト：
   * Web フォント読み込み時のチラつきを低減するための設定
   * -webkit-text-stroke の値に応じて変更
   */
  font-weight: 200;
}

/*
 * 特例文字のスタイリング
 */
.typesetting-char-arrow {
  font-family: 'arial', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.typesetting-char-parenthesis {
  font-family: 'ojuju', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 110%;
  line-height: 1;
}

.en-section {
  line-height: 1.8;
}

.en-section .typesetting-latin {
  /*
   * 行間の調整。親要素 .typesetting-wrapper の行間と視覚的に合わせます。
   * [.typesetting-wrapper の line-height] ÷ [フォントの拡大率] - [ベースラインの調整値の絶対値] × 2 
   */
  line-height: calc(1.8 / 1.05 - 0.02 * 2);
}

a {
  color: var(--color-main);
  text-decoration: underline;
  text-decoration-thickness: var(--text-decoration-thickness);
  text-underline-offset: var(--text-decoration-offset);
}

a:hover {
  text-decoration: none;
}

/* トグルスイッチのスタイル */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 2.4em;
}

/* スイッチのスライダー部分 */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider {
  background-color: var(--color-switch);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--color-switch);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* 丸みを帯びたスライダー */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* オプションのチェックボックス */
#options {
  margin-bottom: 5rem;
}

#options .option {
  display: inline-block;
  margin-bottom: 0.95em;
}

#options .option label {
  display: inline-block;
  font-size: 1.1rem;
  margin-right: 1.2rem;
  cursor: pointer;
  font-weight: 400;
  font-family: BlinkMacSystemFont, -apple-system, -system-ui, Helvetica, alial, sans-serif;
}

#options .option input:disabled + label {
  color: var(--color-sub);
}

/* テキスト内のチェックボックス */
#target input {
  display: none;
}

/* チェックボックスがチェックされていないときのテキストスタイル */
#target label {
  cursor: pointer;
  color: var(--color-sub);
}

#target label .typesetting-latin {
  cursor: pointer;
  color: var(--color-sub);
  -webkit-text-stroke-color: var(--color-sub);
}

/* チェックボックスがチェックされていないときのホバースタイル */
#target label:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-thickness: 0.04em;
  text-decoration-color: var(--text-decoration-thickness);
  text-underline-offset: var(--text-decoration-offset);
}

/* チェックボックスがチェックされているときのテキストスタイル */
#target input:checked + label {
  color: var(--color-main);
}

#target input:checked + label .typesetting-latin {
  color: var(--color-main);
  -webkit-text-stroke-color: var(--color-main);
}

/* チェックボックスがチェックされているときのホバースタイル */
#target input:checked + label:hover {
  color: var(--color-main);
  text-decoration-color: var(--color-main);
}
