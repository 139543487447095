:root {
  --color-main: #09090b;
  --color-sub: #b0abb2;
  --color-switch: #007bff;
  --color-select: #e4e4e7;
  --text-decoration-thickness: .04em;
  --text-decoration-offset: .25em;
}

::selection {
  background: var(--color-select);
}

::-moz-selection {
  background: var(--color-select);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 3.2rem 1.6rem;
  font-family: aktiv-grotesk, a-otf-gothic-bbb-pr6n, BlinkMacSystemFont, -apple-system, -system-ui, sans-serif;
}

@media (min-width: 500px) {
  body {
    margin: 6rem 3rem;
  }
}

article {
  font-feature-settings: "palt";
  letter-spacing: .12em;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2;
}

@media (min-width: 500px) {
  article {
    font-size: 1.4rem;
  }
}

h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 400;
}

@media (min-width: 500px) {
  h1 {
    font-size: 1.8rem;
  }
}

.typesetting-wrapper {
  -webkit-text-stroke: .01em transparent;
}

.typesetting-latin {
  vertical-align: .02em;
  letter-spacing: .05em;
  -webkit-text-stroke: .014em var(--color-main);
  font-size: 104%;
  font-weight: 200;
  line-height: 1.88308;
}

.typesetting-char-arrow {
  font-family: arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.typesetting-char-parenthesis {
  font-family: ojuju, sans-serif;
  font-size: 110%;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.en-section {
  line-height: 1.8;
}

.en-section .typesetting-latin {
  line-height: 1.67429;
}

a {
  color: var(--color-main);
  text-decoration: underline;
  text-decoration-thickness: var(--text-decoration-thickness);
  text-underline-offset: var(--text-decoration-offset);
}

a:hover {
  text-decoration: none;
}

.switch {
  width: 60px;
  height: 34px;
  margin-bottom: 2.4em;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .2s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  background-color: #fff;
  width: 26px;
  height: 26px;
  transition: all .2s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

input:checked + .slider {
  background-color: var(--color-switch);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--color-switch);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#options {
  margin-bottom: 5rem;
}

#options .option {
  margin-bottom: .95em;
  display: inline-block;
}

#options .option label {
  cursor: pointer;
  margin-right: 1.2rem;
  font-family: BlinkMacSystemFont, -apple-system, -system-ui, Helvetica, alial, sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  display: inline-block;
}

#options .option input:disabled + label {
  color: var(--color-sub);
}

#target input {
  display: none;
}

#target label {
  cursor: pointer;
  color: var(--color-sub);
}

#target label .typesetting-latin {
  cursor: pointer;
  color: var(--color-sub);
  -webkit-text-stroke-color: var(--color-sub);
}

#target label:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-thickness: .04em;
  -webkit-text-decoration-color: var(--text-decoration-thickness);
  text-decoration-color: var(--text-decoration-thickness);
  text-underline-offset: var(--text-decoration-offset);
}

#target input:checked + label {
  color: var(--color-main);
}

#target input:checked + label .typesetting-latin {
  color: var(--color-main);
  -webkit-text-stroke-color: var(--color-main);
}

#target input:checked + label:hover {
  color: var(--color-main);
  -webkit-text-decoration-color: var(--color-main);
  text-decoration-color: var(--color-main);
}

@font-face {
  font-family: ling-one;
  src: url("data:font/woff2;base64,AAEAAAAKAIAAAwAgT1MvMhG03xcAAAEoAAAAYGNtYXAADABzAAABkAAAADRnbHlmJAM2GwAAAcwAAAAYaGVhZCLbfFwAAACsAAAANmhoZWEnEvkrAAAA5AAAACRobXR4AAEAAAAAAYgAAAAIbG9jYQAMABgAAAHEAAAACG1heHAAcQAiAAABCAAAACBuYW1lBbsdPAAAAeQAAACKcG9zdHQrY6cAAAJwAAAALQABAAAAAQAAsWqD8F8PPPUAAycQAAAAAOHcugAAAAAA4edeTwAAAAAAAQABAAAABwACAAAAAAAAAAEAACcQ+DAAAAB9AAAAfAABAAEAAAAAAAAAAAAAAAAAAAABAAEAAAADAAMAAQAAAAAAAQACAB4ABgAAAGQAAAAAAAAAAwB9AZAABQAIGWQXcAAAAu4ZZBdwAAANrAAyC7gAAAAABQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAc3N6bQBAACAAIB9A+DAH0CcQB9AAAAABAAAAABOIG1gAAAAgAAAAAQAAAAAAAAAAAAIAAAADAAAAFAADAAEAAAAUAAQAIAAAAAQABAABAAAAIP//AAAAIP///+EAAQAAAAAAAAAMAAwADAABAAAAAAABAAEAAgAANRcjAQEBAQAAAAAAAAYATgADAAEECQABAAgAAAADAAEECQACAAIACAADAAEECQADAB4ACgADAAEECQAEAAwAKAADAAEECQAFAAgANAADAAEECQAGAAwAKABMAGkAbgBnADEAMQAuADAAOwBzAHMAegBtADsATABpAG4AZwAtADEATABpAG4AZwAtADEAdgAxAC4AMAAAAAIAAAAAAAD/tQAyAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwAAAAMBAgRuYnNwAAAA") format("woff2");
}

.typesetting-wrapper wbr {
  visibility: hidden;
}

.typesetting-word-break {
  word-break: keep-all;
  overflow-wrap: anywhere;
}

.typesetting-thin-space, .typesetting-kerning {
  letter-spacing: 0;
  -webkit-user-select: none;
  user-select: none;
}

.typesetting-thin-space[data-content]:after, .typesetting-kerning[data-content]:after {
  content: attr(data-content);
  font-family: ling-one;
  line-height: 0;
}

.typesetting-no-breaks {
  letter-spacing: 0;
}

/*# sourceMappingURL=index.8beecf30.css.map */
